<template>
  <div :key="myData.data.privateBookings.length">
    <h3 class="flix-html-h3" v-if="summary">{{ summary }}</h3>
    <div class="flix-list-group" v-if="myData.data.privateBookings.length">
      <div class="flix-list-group-item" v-for="(booking, index) in myData.data.privateBookings" :key="index">
        <flixIcon :id="'user'" /> <b>{{ booking.name }}</b>
         | <span v-if="booking.commitment == 1" class="flix-text-success"><flixIcon :id="'thumbs-up'"/> {{ $t('message.commitment') }}</span> <span class="flix-text-danger" v-else><flixIcon :id="'thumbs-down'"/> {{ $t('message.cancellation') }}</span>
         &nbsp; <small class="flix-html-small">({{ getDate(booking.date) }})</small>
         <div class="flix-pull-right"><deleteBooking :setDelete="function(){ setDelete(index) }"/></div>
         <div class="flix-clearfix"></div>
         <div class="flix-html-small" v-if="booking.comment"><hr class="flix-html-hr" style="margin: 5px 0"><flixIcon :id="'comments'" /> {{ booking.comment }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    deleteBooking () { return import('@/components/privateEvent/bookingDelete') }
  },
  props: {
    data: Object,
    setReload: Function
  },
  data () {
    return {
      summary: false,
      myData: JSON.parse(JSON.stringify(this.data))
    }
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
  },
  methods: {
    timeoutData () {
      this.$store.dispatch('getPrivateAssistent', { id: this.myData.ID, callback: function (data) { this.myData = data }.bind(this) })
      this.timeout = setTimeout(function () {
        this.timeoutData()
      }.bind(this), 15000)
    },
    setDelete (index) {
      this.$flix_post({
        url: 'assistent/privates/delete_booking',
        data: { form: this.data.ID, data: this.data.data.privateBookings[index] },
        callback: function (ret) {
          this.myData.data.privateBookings.splice(index, 1)
          this.data.data.privateBookings.splice(index, 1)
          this.getSummary()
          this.setReload()
        }.bind(this)
      })
    },
    getDate (d) {
      var date = this.$createDate(d)
      return this.$getNullsBefore(date.getDate()) + '.' + this.$getNullsBefore(date.getMonth() + 1) + '.' + this.$getNullsBefore(date.getFullYear()) + ', ' + this.$getNullsBefore(date.getHours()) + ':' + this.$getNullsBefore(date.getMinutes()) + ' ' + this.$t('message.oClock')
    },
    getSummary () {
      if (typeof this.data.data.privateBookings !== 'object' || this.data.data.privateBookings.length === 0) {
        this.summary = false
        return false
      }
      var sum = [0, 0]
      this.data.data.privateBookings.forEach(function (booking) {
        if (booking.commitment.toString() === '1') {
          sum[0] += 1
        } else {
          sum[1] += 1
        }
      })
      this.summary = sum[0] + ' x ' + this.$t('message.commitment')
      this.summary += ', '
      this.summary += sum[1] + ' x ' + this.$t('message.cancellation')
    }
  },
  mounted () {
    this.timeoutData()
    this.getSummary()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
